import React from "react";
import styles from "./styles.module.scss";
import Header from "../../components/Header";
import HomeTabs from "../../components/HomeTabs";
import { useLocale } from "antd/es/locale";
const { container } = styles;
const Home = () => {
  const { locale } = useLocale();

  return (
    <div className={container} style={{ direction: locale === "ar" ? "rtl" : "ltr" }}>
      <Header />
      <HomeTabs />

    </div>
  );
};

export default Home;
