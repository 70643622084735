import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Menu, MenuItem, IconButton, Typography } from '@mui/material';
import { useLocale } from "../../hooks/localeContext";
import LanguageIcon from "@mui/icons-material/Language";
import { FormattedMessage } from "react-intl";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { changeLocale, locale } = useLocale();

  const handleLanguageChange = (event) => {
    changeLocale(event.currentTarget.getAttribute('data-key'));
    setAnchorEl(null); // Close the Menu
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className={styles.header}
    >
      <div className={styles.logoContainer} onClick={() => window.location.href = '/'}>
        <img src="/assets/images/Mindset_Logo_Mindset Green.png" loading="lazy" alt="" className={styles.logo} />
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          BluePrint
        </div>
        <div className={styles.langContainer}>
          <IconButton
            onClick={handleClick}
            className={styles.langIcon}
          >
            <LanguageIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={styles.langPopover}
            MenuListProps={{ className: styles.menuList }}
            PaperProps={{
              className: styles.menuPaper,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem
              data-key="en"
              onClick={handleLanguageChange}
              selected={locale === 'en'}
              className={styles.menuItem}

            >
              <Typography variant="body1">
                <FormattedMessage id="English" />
              </Typography>
            </MenuItem>
            <MenuItem
              data-key="ar"
              onClick={handleLanguageChange}
              selected={locale === 'ar'}
              className={styles.menuItem}
            >
              <Typography variant="body1">
                <FormattedMessage id="Arabic" />
              </Typography>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
