import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Typography, Box, IconButton, Stepper, Step, StepLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, StepIcon } from '@mui/material';
import { CloudUpload, Description, Delete, CloudDownload, Replay, Celebration, CheckCircle } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import Confetti from 'react-confetti';
import { FormattedMessage, useIntl } from "react-intl";
import { useLocale } from "../../hooks/localeContext";
import styles from './styles.module.scss';
import io from 'socket.io-client';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import serverUrl from '../../shared/http/constant';
import Footer from '../Footer';

const socket = io(serverUrl, {
  transports: ['websocket', 'polling'], // Ensure WebSocket transport is enabled
});

const FromWordToExcel = () => {
  const [progress, setProgress] = useState(0);
  const [waitingProgress, setWaitingProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(null);
  const [filename, setFilename] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [file, setFile] = useState(null);
  const [stage, setStage] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const { locale } = useLocale();
  const [error, setError] = useState('');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false); // New state for reset confirmation dialog
  const intl = useIntl();

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const steps = [
    <FormattedMessage id="reading" defaultMessage="Reading" />,
    <FormattedMessage id="writing" defaultMessage="Writing" />
  ];

  useEffect(() => {
    socket.on('progress_word', (data) => {
      if (data.task_id === taskId) {
        setProgress(data.progress);
        setRemainingTime(data.remaining_time);
        setStage(data.stage); // Update the stage
      }
    });
    socket.on('waiting_progress', (data) => {
      if (data.task_id === taskId) {
        setWaitingProgress(data.progress);
      }
    });
    socket.on('canceled_word', (data) => {
      if (data.task_id === taskId) {
        resetState();
        alert(data.message);
      }
    });

    socket.on('error_word', (data) => {
      if (data.task_id === taskId) {
        toast.error(intl.formatMessage({ id: 'errorConverting', defaultMessage: 'Error when converting' }), {
          className: styles.toastError,
        });
      }
    });

    socket.on('completed_word', (data) => {
      if (data.task_id === taskId) {
        setProgress(100);
        setIsProcessing(false);
        setStage('completed');
        setTaskId(null);
        localStorage.removeItem('wordToExcelTaskId');
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
        setEndTime(Date.now());
      }
    });

    return () => {
      socket.off('progress_word');
      socket.off('waiting_progress');
      socket.off('canceled_word');
      socket.off('completed_word');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);
  useEffect(() => {
    if (startTime && endTime) {
      const totalTime = (endTime - startTime) / 1000; // Calculate total time in seconds
      console.log(`Total time taken: ${totalTime} seconds`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime]);
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      const mimeType = acceptedFiles[0].type;
      // Check for valid MIME types
      const validMimeTypes = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/msword' // .doc (common fallback)
      ];
      if (validMimeTypes.includes(mimeType)) {
        setFile(acceptedFiles[0]);
        setError('');
      } else {
        setError(<FormattedMessage id="errorUploadWord" defaultMessage="Invalid file type. Please upload a .docx file." />);
      }
    } else {
      setError(<FormattedMessage id="errorUploadWord" defaultMessage="Please upload a single .docx file." />);
    }
  };


  const handleStartConvert = async () => {
    const formData = new FormData();
    formData.append('file', file);
    setIsProcessing(true);
    setStartTime(Date.now());

    try {
      const response = await axios.post(`${serverUrl}/process_word`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setTaskId(response.data.task_id);
        localStorage.setItem('wordToExcelTaskId', response.data.task_id);
        setFilename(response.data.random_filename);
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsProcessing(false);
      toast.error('Error uploading file. Please try again.');
    }
  };
  const handleCancel = async () => {
    setIsCanceling(true);
    try {
      await axios.post(`${serverUrl}/cancel_word_processing`, { task_id: taskId });
      setIsCanceling(false);
      setCancelDialogOpen(false);
      resetState();
    } catch (error) {
      console.error('Error canceling processing:', error);
      toast.error('Error canceling processing. Please try again.');
      setIsCanceling(false);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(`${serverUrl}/download_excel/${filename}`, {
        responseType: 'blob', // Ensure the response is a Blob
      });

      // Create a URL for the file
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');

      // Set the download attribute with a filename
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);

      // Append to the document and trigger the download
      document.body.appendChild(fileLink);
      fileLink.click();

      // Cleanup
      fileLink.remove();

      // Reset states after 30 seconds
      setTimeout(resetState, 3000);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Error downloading file. Please try again.');
    }
  };

  const handleRetry = () => {
    setResetDialogOpen(true);
  };

  const confirmReset = () => {
    resetState();
    setResetDialogOpen(false);
  };

  const resetState = () => {
    setProgress(0);
    setRemainingTime(null);
    setFilename('');
    setIsProcessing(false);
    setTaskId(null);
    localStorage.removeItem('wordToExcelTaskId');
    setFile(null);
    setStage(''); // Reset the stage
    setShowConfetti(false);
    setStartTime(null);
    setEndTime(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: false,
  });

  return (<div className={styles.root}>
    <div className={styles.container}>
      <ToastContainer
        autoClose={false}
        position="top-center"
        closeButton={
          ({ closeToast }) => (
            <IconButton onClick={() => { closeToast(); resetState(); }}>
              <Replay sx={{ fontSize: '2.5rem' }} />
            </IconButton>
          )
        }
      />

      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <p><FormattedMessage id="Welcome Blueprint" /></p>
        </div>
        <div className={styles.cardContent}>
          <p className={styles.steps}>
            <FormattedMessage id="steps" defaultMessage="To use Blueprint, follow these three simple steps:" />
          </p>
          <p className={locale === 'en' ? styles.stepEN : styles.stepAR}>
            <FormattedMessage id="stepOne" defaultMessage="Step 1: Download a Word or Excel template from the top right corner of this page." />
          </p>
          <p className={locale === 'en' ? styles.stepEN : styles.stepAR}>
            <FormattedMessage id="stepTwo" defaultMessage="Step 2: Follow the instructions in the Word document. (Note: The Excel sheet is a standard ODK-structured form and does not contain instructions.)" />
          </p>
          <p className={locale === 'en' ? styles.stepEN : styles.stepAR}>
            <FormattedMessage id="stepThree" defaultMessage="Step 3: Upload your Word document to convert it to an Excel form, or upload your Excel form to convert it to a Word document for review or editing." />
          </p>
          <p className={locale === 'en' ? styles.stepMoreQueEn : styles.stepMoreQueAr}>
            <FormattedMessage id="more questions" defaultMessage="For comments or questions, please email" />
            <a href="mailto:info@mindset-res.com">
              <FormattedMessage id="mindsetEmail" defaultMessage=" info@mindset-res.com." />
            </a>
          </p>
        </div>
      </div>

      <div {...getRootProps({ className: `${styles.uploadContainer} ${error ? styles.errorBorder : ''}` })}>
        <input {...getInputProps()} />
        <CloudUpload className={styles.uploadIcon} />
        <Typography variant="h6" component="span" className={styles.uploadText}>
          {isDragActive ? <FormattedMessage id="uploadPrompt" /> : <FormattedMessage id="uploadPrompt" />}
        </Typography>
        <Typography variant="body2" component="span" className={styles.uploadHint}>
          <FormattedMessage id="uploadHintWord" />
        </Typography>
      </div>

      {error && (
        <Typography variant="body2" color="error" className={styles.errorMessage}>
          {error}
        </Typography>
      )}

      {file && (
        <div className={styles.fileContainer}>
          <div className={styles.filePreview}>
            <Description className={styles.fileIcon} />
            <Typography variant="body1" component="span" className={styles.fileName}>
              {file.name}
            </Typography>
            <IconButton onClick={() => setFile(null)} className={styles.deleteButton}>
              <Delete />
            </IconButton>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStartConvert}
            disabled={!file}
            className={styles.convertButton}
          >
            <FormattedMessage id="convert" />
          </Button>
        </div>
      )}

      {isProcessing && (
        <div className={styles.overlay}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={styles.circularProgressContainer}>
            <Typography variant="body1" className={styles.convertingMessage}>
              <FormattedMessage id="converting" />
            </Typography>
            <Stepper activeStep={stage === 'completed' ? 2 : stage === 'writing' ? 1 : 0} alternativeLabel className={styles.stepper}>
              {steps?.map((label, index) => (
                <Step key={index} className={styles.step}>
                  <StepLabel StepIconComponent={StepIconComponent} className={styles.stepLabel}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {waitingProgress === 1 && progress === 0 ? (
              <CircularProgress className={styles.circularProgress} />
            ) : stage !== 'completed' && (
              <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" value={progress} className={styles.circularProgress} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="caption" component="div" color="textSecondary" className={styles.progressMessage}>
                    {`${Math.round(progress)}%`}
                  </Typography>
                </Box>
              </Box>
            )}
            {remainingTime !== null && (
              <Typography variant="body1">
                <FormattedMessage id="estimatedTime" values={{ time: Math.round(remainingTime * 100) / 100 }} />
              </Typography>
            )}
          </Box>
          <Button onClick={() => setCancelDialogOpen(true)} className={styles.cancelButton}>
            <FormattedMessage id="cancel" />
          </Button>
        </div>
      )}

      {stage === 'completed' && (
        <div className={styles.overlay}>
          <IconButton className={styles.retryButton} onClick={handleRetry}>
            <Replay sx={{ fontSize: '2.5rem' }} />
          </IconButton>
          <Stepper activeStep={stage === 'completed' ? 2 : stage === 'writing' ? 1 : 0} alternativeLabel className={styles.stepperAfter}>
            {steps.map((label, index) => (
              <Step key={index} className={styles.step}>
                <StepLabel StepIconComponent={StepIconComponent} className={styles.stepLabel}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Celebration className={styles.successIcon} />
          <Typography variant="h5" className={styles.successMessage}>
            <FormattedMessage id="conversionSuccess" />
          </Typography>
          <Button color="primary" startIcon={<CloudDownload />} className={styles.downloadButton} onClick={handleDownload}>
            <div>
              <span className={styles.downloadText} style={locale === 'en' ? { marginLeft: '0.5rem' } : { marginRight: '1.5rem' }}>
                <FormattedMessage id="downloadWord" />
              </span>
            </div>
          </Button>
          {showConfetti && <Confetti className={styles.confettiContainer} />}
        </div>
      )}

      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
      >
        <DialogTitle><FormattedMessage id="confirmCancellation" /></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="cancelConfirmationMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)} color="primary">
            <FormattedMessage id="no" />
          </Button>
          <Button onClick={handleCancel} color="primary" autoFocus disabled={isCanceling}>
            {isCanceling ? <CircularProgress size={24} /> : <FormattedMessage id="yes" />}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={resetDialogOpen}
        onClose={() => setResetDialogOpen(false)}
      >
        <DialogTitle><FormattedMessage id="confirmReset" /></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="resetConfirmationMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResetDialogOpen(false)} color="primary">
            <FormattedMessage id="no" />
          </Button>
          <Button onClick={confirmReset} color="primary" autoFocus>
            <FormattedMessage id="yes" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    <div className={styles.footer}>
      <Footer />
    </div>
  </div>

  );
};

const StepIconComponent = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { active, completed, className } = props;
  return (
    <div className={className}>
      {completed ? <CheckCircle style={{ fontSize: '3rem' }} /> : <StepIcon {...props} style={{ fontSize: '3rem' }} />}
    </div>
  );
};

export default FromWordToExcel;
