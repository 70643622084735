import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Tabs, Tab, Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import FromWordToExcel from '../FromWordToExcel';
import styles from './styles.module.scss';
import FromExcelToWord from '../FromExcelToWord';
import { useLocale } from "../../hooks/localeContext";
import { FormattedMessage } from "react-intl";
import serverUrl from '../../shared/http/constant';
import axios from 'axios';
import { Download as DownloadIcon } from '@mui/icons-material';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#66b6aa',
    },
    secondary: {
      main: '#1e5050',
    },
    text: {
      primary: '#8c8b8b',
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function HomeTabs() {
  const [value, setValue] = React.useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingTab, setPendingTab] = useState(null);
  const { locale } = useLocale();

  const handleCancelTask = async (taskId, type, key) => {
    try {
      await axios.post(`${serverUrl}/${type}`, { task_id: taskId });
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error canceling processing:', error);
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue === 1 && localStorage.getItem('wordToExcelTaskId')) {
      setPendingTab(newValue);
      setOpenDialog(true);
    } else if (newValue === 0 && localStorage.getItem('excelToWordTaskId')) {
      setPendingTab(newValue);
      setOpenDialog(true);
    } else {
      setValue(newValue);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirm = async () => {
    if (pendingTab === 1 && localStorage.getItem('wordToExcelTaskId')) {
      await handleCancelTask(localStorage.getItem('wordToExcelTaskId'), 'cancel_word_processing', 'wordToExcelTaskId');
    } else if (pendingTab === 0 && localStorage.getItem('excelToWordTaskId')) {
      await handleCancelTask(localStorage.getItem('excelToWordTaskId'), 'cancel_excel_processing', 'excelToWordTaskId');
    }
    setValue(pendingTab);
    setOpenDialog(false);
  };

  const handleDownload = () => {
    const fileUrl = value === 0 ? '/assets/templates_files/Blueprint template.docx' : '/assets/templates_files/Script template.xlsx';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileUrl.split('/').pop();
    document.body.appendChild(link); // Append the link to the document body
    link.click();
    document.body.removeChild(link); // Clean up the link
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%' }} className={styles.tabsContainer} style={{ direction: locale === "ar" ? "rtl" : "ltr" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
            <Tab
              label={<FormattedMessage id="Word to Excel" />}
              icon={
                <img
                  src={value === 0 ? '/assets/images/doc_logo.png' : '/assets/images/doc_logo_gray.png'}
                  alt="Word to Excel"
                  className={`${styles.tabIcon} ${value === 0 ? styles.activeIcon : ''}`}
                  style={locale === "ar" ? { marginLeft: '7px' } : {}}

                />
              }
              iconPosition="start"
              {...a11yProps(0)}
              className={`${styles.tab} ${value === 0 ? styles.activeTab : ''}`}
            />
            <Tab
              label={<FormattedMessage id="Excel to Word" />}
              icon={
                <img
                  src={value === 1 ? '/assets/images/xlsx_logo.png' : '/assets/images/xlsx_logo_gray.png'}
                  alt="Excel to Word"
                  className={`${styles.tabIcon} ${value === 1 ? styles.activeIcon : ''}`}
                  style={locale === "ar" ? { marginLeft: '7px' } : {}}
                />
              }
              iconPosition="start"
              {...a11yProps(1)}
              className={`${styles.tab} ${value === 1 ? styles.activeTab : ''}`}
            />
          </Tabs>
          <Button
            className={styles.downloadButton}
            startIcon={<DownloadIcon />}
            onClick={handleDownload}
            style={locale === "ar" ? { marginRight: 'auto', marginLeft: '25px', direction: 'ltr' } : { marginRight: '30px', marginLeft: 'auto', direction: 'ltr' }}
          >
            <p>{value === 0 ? <FormattedMessage id="Download Word Template" /> : <FormattedMessage id="Download Excel Template" />}</p>
          </Button>


        </Box>
        <TabPanel value={value} index={0}>
          <FromWordToExcel />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FromExcelToWord />
        </TabPanel>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="Cancel Process Confirmation" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {pendingTab === 1 ? (
              <FormattedMessage id="Are you sure you want to cancel the process in Word to Excel?" />
            ) : (
              <FormattedMessage id="Are you sure you want to cancel the process in Excel to Word?" />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            <FormattedMessage id="no" />
          </Button>
          <Button onClick={handleDialogConfirm} color="primary" autoFocus>
            <FormattedMessage id="yes" />
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider >
  );
}
