import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import styles from './styles.module.scss';
const Footer = () => {

  return (
    <Box
      className={styles.footer}
    >
      <Container maxWidth="lg">
        <Typography variant="body1" className={styles.footerText}>
          © 2024 Mindset
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
