// src/App.js
import React from "react";
import { IntlProvider } from "react-intl";
import { Route, Router } from "wouter";
import Home from "./pages/home";
import { LocaleProvider, useLocale } from "./hooks/localeContext";

function App() {
  return (
    <LocaleProvider>
      <MainContent />
    </LocaleProvider>
  );
}

function MainContent() {
  const { locale } = useLocale();

  const translations = require(`./shared/locales/${locale}.json`);

  return (
    <IntlProvider locale={locale} messages={translations}>
      <Router base="/">
        <Route path="/" component={Home} />
      </Router>
    </IntlProvider>
  );
}

export default App;
